import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import Header from "../../header/Header";
import HeaderModulo from "../../header/HeaderModulo";
import Aside from "../../aside/Aside";
import { alerta, toast, formatDate } from '../../../services/utilities';
import { getComercios } from '../../../services/Usuario';
import { getReporteVentaDetallda } from '../../../services/Reportes';
import DetalleVenta from './DetalleVenta';
import { setIdTransaccion} from '../../../actions'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function VentaDetallada(props){

    useEffect(() => {
        handleConsultarComercios();
    }, []);

    const tooltip1 = (<Tooltip id="tooltip">Venta Bruta</Tooltip>);
    const tooltip2 = (<Tooltip id="tooltip">Neto Vendedor</Tooltip>);
    const tooltip3 = (<Tooltip id="tooltip">Neto Promtor</Tooltip>);
    const tooltip4 = (<Tooltip id="tooltip">Neto Admin Externo</Tooltip>);
    const tooltip5 = (<Tooltip id="tooltip">Cobros Vendedor</Tooltip>);
    const tooltip6 = (<Tooltip id="tooltip">Cobros Promotor</Tooltip>);
    const tooltip7 = (<Tooltip id="tooltip">Cobros Admin Externo</Tooltip>);
    const tooltip8 = (<Tooltip id="tooltip">Neto Empresa</Tooltip>);

    const [spinerUsuarios, setSpinerUsuarios] = useState(true);
    const [spinerTabla, setSpinerTabla] = useState(false);
    const [verMnesaje, setVerMensaje] = useState(true);
    const [verTable, setVerTable] = useState(false);
    const [listsUsuarios, setListaUsuarios] = useState([]);
    const [listaVentas, setlistaVentas] = useState([]);
    const [resumen, setResumen] = useState({});
    const [filter, setFilter] = useState('');
    const [filterTabla, setFilterTabla] = useState('');

    const [filtro, setFiltro] = useState({
        "fecha_ini": formatDate(new Date()),
        "fecha_fin": formatDate(new Date()),
        "modalidad":"",
        "tipo_comercio":"99",
        "usuario": "",
        "detalle": ""
    });

    const filterData = listsUsuarios.filter((item) => {
        const TipoComercioMatch = item.tipo.toLowerCase().includes(filter.toLowerCase());
        return TipoComercioMatch;
    });

    const filterDataTabla = listaVentas.filter((item) => {

        const vendedorMatch = item.vendedor !== null && item.vendedor  ? item.vendedor.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const categoriaMatch = item.categoria !== null && item.categoria ? item.categoria.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const DetalleMatch = item.detalle_venta !== null && item.detalle_venta ? item.detalle_venta.toLowerCase().includes(filterTabla.toLowerCase()) : '';
        const idMatch =  item.id_transaccion !== null && item.id_transaccion ?  item.id_transaccion.toString().includes(filterTabla) : '';
        const idComercioMatch = item.id_comercio !== null && item.id_comercio  ?  item.id_comercio.toString().includes(filterTabla) : '';
        const valorMatch = item.valor !== null && item.valor  ?  item.valor.toString().includes(filterTabla) : '';
        return vendedorMatch || categoriaMatch || idMatch || idComercioMatch || valorMatch || DetalleMatch;
    });

    const handleInputFiltroChange = (event) => {
        const { name, value } = event.target;
        setFiltro({ ...filtro, [name]: value });

        if(name === "tipo_comercio"){
            setFilter(value)
        }
    };

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleChangeMultiUsuario = (selected) => {
        setSelectedOptions(selected);
        handleInputFiltroChange({
            target: {
                name: 'usuario',
                value: selected ? selected.map(option => option.value) : []
            }
        });
    };

    const formatearMoneda = (value) =>{
        const valor = parseFloat(value);
        if (!isNaN(valor)) { 
          value = valor.toLocaleString('es-CO', {minimumFractionDigits: 0});
        }else{
            value = 0;
        }
        return value;
    }

    const handleConsultarComercios = async () => {
       
        try {
            await getComercios().then( response => {

                if(response.status !== undefined && response.status === 200){
                    setListaUsuarios(response.data.data);  
                }else{
                    toast('No tiene usuarios de este tipo o fallo la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación del select de usuarios (reportes/ventaDetallada/handleConsultarComercios) ->'+ error);
        }finally {
            setSpinerUsuarios(false);
        }
    }

    const handleBuscarVentas = async () => {

        if(filtro.tipo_comercio === "99"){
            toast('Seleccione un tipo de usuario','Tipo de usuario invalido');
            return;
        }

        var idComercioArray = filterData.map(function(item) {
            return item.id_comercio;
        });

        var ids_comercio = idComercioArray.join(',');
        var id_comercio = filtro.usuario.length === 0 ? ids_comercio : filtro.usuario;

        setVerMensaje(false);
        setSpinerTabla(true);
        setVerTable(false);

        try {
            await getReporteVentaDetallda(filtro.fecha_ini, filtro.fecha_fin, filtro.modalidad, filtro.tipo_comercio, id_comercio, filtro.detalle).then( response => {

                if(response.status !== undefined && response.status === 200){
                    
                    if(response.data.ventas.length > 0){
                        setlistaVentas(response.data.ventas);
                        setResumen(response.data.resumen);
                        setVerTable(true);
                    }else{
                        setVerMensaje(true)
                        setVerTable(false);
                    }

                }else{
                    toast('Error al ejecutar la consulta','');
                }
            });

         }catch (error) {
            alerta('Error no controlado al cargar la infromación de ventas  (reportes/ventaDetallada/handleBuscarVentas) ->'+ error);
        }finally {
            setSpinerTabla(false);
        }
    }

    const handleTransaccionSeleccionada = (id_transaccion) => {
        props.setIdTransaccion(id_transaccion)
    }

    return(
        <React.Fragment>
            <Header></Header>
            <Aside></Aside>
            <main id="main" className="main">
                <HeaderModulo nombre_modulo={props.modulo}></HeaderModulo>
                <section className="section">
                    <div className='row'>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Desde:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_ini" 
                                    name="fecha_ini" 
                                    value={filtro.fecha_ini}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Hasta:</label>
                            <div className="input-group">
                                <input 
                                    type="date" 
                                    className="form-control" 
                                    id="fecha_fin" 
                                    name="fecha_fin" 
                                    value={filtro.fecha_fin}
                                    onChange={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Modalidad</label>
                            <div className='input-group'>
                                <select 
                                    className="form-select form-control"
                                    name="modalidad"
                                    value={filtro.modalidad}
                                    onChange={handleInputFiltroChange}
                                >
                                    <option value="">(TODAS)</option>
                                    <option value="1">APUESTA</option>
                                    <option value="2">QUINTA</option>
                                    <option value="3">ASTROS</option>
                                    <option value="4">BONO</option>
                                    <option value="5">RIFA</option>
                                    <option value="6">GANE MAS</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-2 mb-3'>
                            <label className="mb-1">Tipos de Usuario</label>
                            <div className='input-group'>
                                <select 
                                    className="form-select form-control"
                                    name="tipo_comercio"
                                    value={filtro.tipo_comercio}
                                    onChange={handleInputFiltroChange}
                                >
                                    <option value="99">-Seleccione-</option>
                                    { props.data_sesion.rol_id === 0?
                                    <>
                                        <option value="">(TODOS)</option>
                                        <option value="ADMIN-EXTERNO">SOCIO EMPRESA</option>
                                        <option value="PROMOTOR">PROMOTOR</option>
                                        <option value="VENDEDOR">VENDEDOR</option>
                                    </>
                                    :''}
                                    { props.data_sesion.rol_id === 1?
                                    <>  
                                        <option value="PROMOTOR">PROMOTOR</option>
                                    </>
                                    :''}
                                    { props.data_sesion.rol_id === 2?
                                    <>
                                        <option value="VENDEDOR">VENDEDOR</option>
                                    </>
                                    :''}
                                </select>
                            </div>
                        </div>
                        {filtro.tipo_comercio !== '99' ?
                            <div className='col-md-3'>
                                <label className="mb-1">{filtro.tipo_comercio === 'PROMOTOR' ? 'Promotores' : filtro.tipo_comercio === 'ADMIN-EXTERNO' ? 'Administradores' : filtro.tipo_comercio === 'VENDEDOR' ? 'Vendedores' : 'Usuarios' }</label>
                                {spinerUsuarios ?
                                    <div className="d-flex">
                                        <div className="spinner-border" role="status"></div>                 
                                    </div>
                                :
                                    <div className='input-group'>
                                        <Select
                                            isMulti
                                            name="usuario"
                                            options={Array.isArray(filterData) ? filterData.map(usuario => ({
                                                value: usuario.id_comercio,
                                                label: `${usuario.id_comercio} - ${usuario.nombre} (${usuario.alias})`
                                            })) : []}
                                            value={selectedOptions}
                                            onChange={handleChangeMultiUsuario}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="(TODOS)"
                                            styles={{
                                                container: (provided) => ({
                                                    ...provided,
                                                    width: '100%'
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    width: '100%'
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    width: '100%'
                                                })
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        :''}
                        <div className='col-md-3 mb-3'>
                            <label className="mb-1">Detalles</label>
                            <div className="input-group">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="detalle" 
                                    name="detalle" 
                                    onBlur={handleInputFiltroChange} 
                                />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <label className="mb-1">&emsp;</label>
                            <div className="input-group">
                                <button 
                                    type="button" 
                                    className="btn btn-outline-primary btn-md"
                                    onClick={handleBuscarVentas}
                                >
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>
                    {verTable ?
                        <>
                        <div className="row">
                            <div className='col-md-3 mt-1'>
                                <OverlayTrigger placement="top" overlay={tooltip1}>
                                    <div className="card-totales-venta">
                                        <h6 className="m-0"><b>V. Bruta</b></h6>
                                        <h6 className="text-secondary m-0"><b>$ {formatearMoneda(resumen.venta_bruta)}</b></h6>
                                    </div>
                                </OverlayTrigger>
                            </div>
                            <div className='col-md-3 mt-1'>
                                <OverlayTrigger placement="top" overlay={tooltip2}>
                                <div className="card-totales-venta">
                                    <h6 className="m-0"><b>N. Vendedor</b></h6>
                                    <h6 className="text-danger m-0"><b>$ {formatearMoneda(resumen.neto_vendedor)}</b></h6>
                                </div>
                                </OverlayTrigger>
                            </div>
                            <div className='col-md-3 mt-1'>
                                <OverlayTrigger placement="top" overlay={tooltip3}>
                                    <div className="card-totales-venta">
                                        <h6 className="m-0"><b>N. Promotor</b></h6>
                                        <h6 className="text-danger m-0"><b>$ {formatearMoneda(resumen.neto_promotor)}</b></h6>
                                    </div>
                                </OverlayTrigger>
                            </div>
                            <div className='col-md-3 mt-1'>
                                <OverlayTrigger placement="top" overlay={tooltip4}>
                                    <div className="card-totales-venta">
                                        <h6 className="m-0"><b>N. Adm Ext</b></h6>
                                        <h6 className="text-danger m-0"><b>$ {formatearMoneda(resumen.neto_admin_externo)}</b></h6>
                                    </div>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col-md-3 mt-1'>
                                <OverlayTrigger placement="top" overlay={tooltip5}>
                                    <div className="card-totales-venta" title='Cobros Vendedores'>
                                        <h6 className="m-0"><b>C. Vendedor</b></h6>
                                        <h6 className="text-info m-0"><b>$ {formatearMoneda(resumen.cobro_vendedor)}</b></h6>
                                    </div>
                                </OverlayTrigger>
                            </div>
                            <div className='col-md-3 mt-1'>
                                <OverlayTrigger placement="top" overlay={tooltip6}>
                                    <div className="card-totales-venta">
                                        <h6 className="m-0"><b>C. Promotor</b></h6>
                                        <h6 className="text-info m-0"><b>$ {formatearMoneda(resumen.cobro_promotor)}</b></h6>
                                    </div>
                                </OverlayTrigger>
                            </div>
                            <div className='col-md-3 mt-1'>
                                <OverlayTrigger placement="top" overlay={tooltip7}>
                                    <div className="card-totales-venta">
                                        <h6 className="m-0"><b>C. Adm Ext</b></h6>
                                        <h6 className="text-info m-0"><b>$ {formatearMoneda(resumen.cobro_admin_externo)}</b></h6>
                                    </div>
                                </OverlayTrigger>
                            </div>
                            <div className='col-md-3 mt-1'>
                                <OverlayTrigger placement="top" overlay={tooltip8}>
                                    <div className="card-totales-venta">
                                        <h6 className="m-0"><b>N. Empresa</b></h6>
                                        <h6 className="text-success m-0"><b>$ {formatearMoneda(resumen.neto_empresa + resumen.cobro_vendedor + resumen.cobro_promotor + resumen.cobro_admin_externo)}</b></h6>
                                    </div>
                                </OverlayTrigger>
                            </div>
                        </div>
                        </>
                    :''}
                    {verTable ?
                    <>     
                        <div className='d-flex justify-content-between align-items-center mb-1'>
                            <div className="col-md-3">
                                {/*<div className="input-group">
                                    <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                                    <input 
                                        type="text" 
                                        className="form-control form-control-sm" 
                                        placeholder="Buscar..." 
                                        aria-label="Buscar" 
                                        aria-describedby="basic-addon1" 
                                        value={filterTabla}
                                        onChange={(e) => setFilterTabla(e.target.value)} 
                                    />
                                </div>*/}
                            </div>
                            <span className='text-secondary mx-4' style={{fontSize :'12px'}}>Total Registros {filterDataTabla.length}</span>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className='table-container'>
                                    <div className="table-responsive-sm">
                                        <table className='table table-hover'>
                                            <thead>
                                            <tr className='text-center'>
                                                <td><b>Transaccion</b></td>
                                                <td><b>Fecha</b></td>
                                                <td><b>Hora</b></td>
                                                <td><b>Vendedor</b></td>
                                                <td><b>Modalidad</b></td>
                                                <td><b>Valor</b></td>
                                                {/*<td><b>Cliente</b></td>*/}
                                                <td><b>Resumen</b></td>
                                                <td><b><i className="bi bi-clipboard-heart"></i> detalle</b></td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {Array.isArray(listaVentas) ? filterDataTabla.map((venta, index) => (
                                                <tr key={index} className='align-middle'>
                                                    <td className='text-center'><span className="badge text-bg-warning"># {venta.id_transaccion}</span></td>
                                                    <td className='text-center'><span className="badge text-bg-dark">{venta.fecha}</span></td>
                                                    <td className='text-center'><span className="badge text-bg-dark">{venta.hora}</span></td>
                                                    <td className='text-center' style={{fontSize : '10px'}}><b>{venta.id_comercio} - {venta.vendedor}</b></td>
                                                    <td className={venta.categoria === 'APUESTA' ? 'text-success text-center' : venta.categoria === 'QUINTA' ? 'text-info text-center' : 'text-primary text-center'}><b>{venta.categoria}</b></td>
                                                    <td className='text-center'>${formatearMoneda(venta.valor)}</td>
                                                    {/*<td style={{fontSize : '12px'}}>{venta.nombre_cliente} {venta.celular_cliente}</td>*/}
                                                    <td style={{fontSize : '10px'}}>{venta.nombre_cliente} {venta.detalle_venta}</td>
                                                    <td className='text-center'>
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-primary btn-sm"
                                                            data-id={venta.id_transaccion}
                                                            data-bs-toggle="modal" 
                                                            data-bs-target="#modalDetalleVenta"
                                                            onClick={(e) => handleTransaccionSeleccionada(e.target.getAttribute('data-id'))}
                                                        >detalle
                                                        </button>
                                                    </td>
                                                </tr>
                                            )) : <tr></tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :''}

                    {spinerTabla ?
                    <div className="d-flex justify-content-center align-items-center" style={{ 'height' : '200px'}}>
                        <div className="spinner-border" role="status"></div>                 
                    </div>
                    :''}
                    {verMnesaje ?
                    <div className='text-center text-secondary my-4'>
                        <h3>Sin resultado de búsqueda para los filtros aplicados.</h3>
                        <p>Por favor elija una combinación de filtros diferente.</p>
                    </div>
                    :''}
                </section>
            </main>
            <DetalleVenta></DetalleVenta>
        </React.Fragment>
    )
}

const mapSateToProps = state => {
    return {
        data_sesion: state.data_sesion,
    }
}

const mapDispatchToProps  = {
    setIdTransaccion
};

export default connect(mapSateToProps, mapDispatchToProps)(VentaDetallada)